import consumer from './consumer'

$ ->
  if (pathname = document.location.pathname.split('/'))[pathname.length - 3] is 'classes' and ['dashboard', 'cards'].includes(pathname[pathname.length - 1]) and
     course_id = $('#static_dashboard').data('dashboard').course.uid
    class_dashboard_channel = consumer.subscriptions.create {channel: 'ClassDashboardChannel', id: pathname[pathname.length - 2]},
      connectedState: false; disconnectedAt: null
      dashboard: null; dashboardReady: false
      serverMessages: []; dashboardMessages: []

      connected: ->
        ActionCable.logger.log 'Connected'
        @perform 'send_course_info', {disconnectedAt: @disconnectedAt}
        while (message = @serverMessages.shift()) and message['command'] isnt 'ready'
          ActionCable.logger.log 'Sending buffered message to server', message
          @send message
        @connectedState = true

      disconnected: ->
        ActionCable.logger.log 'Disconnected'
        @connectedState = false
        @disconnectedAt = new Date(consumer.connection.monitor.disconnectedAt).toISOString()

      received: (message) ->
        ActionCable.logger.log 'Received message from server', message
        if @dashboardReady
          ActionCable.logger.log 'Sending unbuffered message to dashboard', message
          @dashboard.postMessage Object.camelizeEntries(message), window.location.origin
        else
          @dashboardMessages.push message

      receivedFromDashboard: (message) ->
        ActionCable.logger.log 'Received message from dashboard', message
        @dashboard = $('#static_dashboard').prop('contentWindow')
        if message['command'] is 'ready'
          while bufferedMessage = @dashboardMessages.shift()
            ActionCable.logger.log 'Sending buffered message to dashboard', bufferedMessage
            @dashboard.postMessage Object.camelizeEntries(bufferedMessage), window.location.origin
          @dashboardReady = true
        else if message['action']
          ActionCable.logger.log 'Received command from dashboard', message
          @perform message['action'], message
        else if @connectedState
          ActionCable.logger.log 'Sending unbuffered message to server', message
          @send message
        else
          @serverMessages.push message

    $(window).on 'message', (event) ->
      if event.originalEvent.origin is window.location.origin and event.originalEvent.data['id'] is course_id
        class_dashboard_channel.receivedFromDashboard event.originalEvent.data
