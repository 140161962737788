import ApplicationController from './application_controller'

export default class extends ApplicationController

  initialize: -> $(@element).tabs()

  # Update the tab when going back to a page
  updateTab: (event) -> $(@).find("li a[href='#{window.location.hash}']").click()

  # Update the page history with the tab anchor
  updateFocusHistory: (event) ->
    $target = $(event.target)
    $target = $target.parent('a') unless $target.filter('a').length
    $(window.document).prop 'title', "#{$(window.document).prop('title').split(' # ')[0]} # #{$target.text()}"
    search = $(window.location).prop('search')
    window.history.replaceState null, '', "#{search}#{$target.prop('hash')}"
#   - alternative that keeps tab choices in window's history
#     pop = true
#     if pop
#       pop = false
#       search = $(window.location).prop('search')
#       window.history.pushState null, '', "#{search}#{$target.prop('hash')}"
