import ApplicationController from './application_controller'

export default class extends ApplicationController
  @outlets: ['login']
  @values: {timeout: Number, sortablePath: String, togglePath: String}
  @targets: ['hSortable', 'vSortable', 'alert', 'notice', 'banner', 'announcement', 'sidebar', 'breadcrumbs', 'menuItem', 'manual']

  # Move sections
  initialize: ->
    @setSessionExpiration() if @hasTimeoutValue
    $(@hSortableTargets).sortable(
      axis: 'x', tolerance: 'pointer', classes: {'ui-sortable': 'horizontal'}, items: '> *',
      handle: '> .sidebar_panel:nth-child(2) > :first-child, \
               > .content_panel:first-child > :first-child, \
               > #main:first-child > :first-child',
      update: (event, ui) =>
        @removePosition(ui.item)
        @adjustSidebarPosition(ui.item)
        @sendOrder(event.target))
    $(@vSortableTargets).sortable(
      axis: 'y', tolerance: 'pointer', classes: {'ui-sortable': 'vertical'}, handle: '> :first-child',
      items: '> :not(h2, #login_modal)', cancel: '.sidebar > :nth-child(2), .content > :first-child',
      update: (event, ui) =>
        @removePosition(ui.item)
        @loginOutlet.setLoginOffset() if @hasLoginOutlet
        @sendOrder(event.target))

  # Display messages on load
  connect: ->
    $(window.visualViewport).on('resize', @storeHeight).on('scroll', @scrollToTop)
    @highlightCurrent() if @hasBreadcrumbsTarget
    $(@bannerTarget).click()
    $(@announcementTarget).slideDown(1000) if @hasAnnouncementTarget
    @addHeadings()
    @setAuthenticityToken()
    @updateAnchor() if location.pathname is '/manual'

  # Set session expiration
  setSessionExpiration: ->
    setInterval (-> $.get '/active', (data) -> window.location = '/timeout' if data is 'false'), (@timeoutValue || 60) * 1000

  # Store window height in a css variable for dynamic height adjustment on onscreen keyboard display
  storeHeight: () -> $(document.documentElement).css '--viewport-height', window.visualViewport.height + 'px'

  # Scroll window back to top
  scrollToTop: -> window.scrollTo(0, 0)

  # Highlight current menu item
  highlightCurrent: ->
    breadcrumbs = $(@breadcrumbsTarget).find('ul li').map(() -> $(@).text())
    page = if breadcrumbs[0] in ['Index', 'Design Manual'] then breadcrumbs[breadcrumbs.length - 1] else breadcrumbs[2] or breadcrumbs[0]
    @menuItemTargets.forEach (target) -> $(target).addClass('current') if page is $(target).find('a span').text().trim()

  # Show messages
  showMessages: (event) ->
    if $(@noticeTarget).find('div').text().length
      $(@alertTarget).before($(@noticeTarget))
    else
      $(@noticeTarget).before($(@alertTarget))
    $(@alertTarget).slideDown(500, => setTimeout (=> $(@alertTarget).click()), 20000) if $(@alertTarget).find('div').text().length
    $(@noticeTarget).slideDown(500, => setTimeout (=> $(@noticeTarget).click()), 20000) if $(@noticeTarget).find('div').text().length
    event.stopPropagation()

  # Hide one message
  hideMessage: -> $(@alertTarget).slideUp 500; $(@noticeTarget).slideUp 500

  # Close design & help pages
  close: -> window.close()

  # Toggle sidebar
  toggleSidebar: (event) ->
    visible = $(@sidebarTarget).is(':visible')
    permanent = $(@sidebarTarget).css('position') isnt 'absolute'
    $(@sidebarTarget).toggle 'slide', {direction: if $(@sidebarTarget).next().length then 'left' else 'right'}, 200
    $.ajax url: @togglePathValue, type: 'GET', data: $.param({visible : not visible}) if permanent
    event.stopPropagation()

  # Hide sidebar on window click for small screens
  hideSidebar: (event) ->
    if @hasSidebarTarget
      visible = $(@sidebarTarget).is(':visible')
      permanent = $(@sidebarTarget).css('position') isnt 'absolute'
      $(@sidebarTarget).hide 'slide', {direction: if $(@sidebarTarget).next().length then 'left' else 'right'}, 200 \
        if not permanent and visible

  # Send order command
  sendOrder: (target) ->
    $.ajax url: @sortablePathValue, type: 'GET', \
           data: $(target).sortable('serialize', key: "#{target.id}[]", expression: '(.*)')

  # Adjust sidebar position on reordering
  adjustSidebarPosition: (item) ->
    if item.css('position') is 'absolute'
      item.filter('aside:first-child').css({left: '0px', right: 'unset'})
      item.filter('aside:last-child').css({left: 'unset', right: '0px'})
      item.prev('aside:first-child').css({left: '0px', right: 'unset'})
      item.next('aside:last-child').css({left: 'unset', right: '0px'})

  # Remove position from moved item
  removePosition: (item) -> item.attr('style', '')

  # Add hidden headings for accessibility
  addHeadings: ->
    $('.panels, .panel_view').find('section.panel').each -> $(@).prepend $('<h3>').text('Attributes') unless $(@).find('h3').length
    $('.panels > section').each -> $(@).prepend $('<h3>').text($(@).prop('id').titleize())
    $('section.filters').each -> $(@).prepend $('<h3>').text('Filters')
    $('section.list').each -> $(@).prepend $('<h3>').text($(@).prop('id').titleize())
    $(':not(.static) > section.buttons').each -> $(@).prepend $('<h3>').text('Actions')
    $('.static > section.buttons').each -> $(@).prepend $('<h5>').text('Actions')

  # Set correct authenticity token for cached forms
  setAuthenticityToken: -> $(@element).find('input[name=authenticity_token]').val($('meta[name=csrf-token]').attr('content'))

  # Set window title & position for manual anchors
  updateAnchor: ->
    if search = $(window.location).prop('search').split('=')[1]
      window.history.replaceState null, '', "/manual##{search}"
      $(@manualTarget).scrollTop $("##{search}").offset().top - $(@manualTarget).offset().top

  # set full screen for phones
  # $ ->
  #   toggleFullScreen = ->
  #     if (!document.fullscreenElement) then document.documentElement.requestFullscreen()
  #     else if (document.exitFullscreen) then document.exitFullscreen()
  #   toggleFullScreen() if navigator.platform == 'Android'
  #   $('body').css('height', $(window).height() + 50) if navigator.platform == 'Android'

  # screen resolution test code
  # $ -> alert("screenWidth = #{$(window).width()}, windowWidth = #{$(document).width()},
  #   screenHeight = #{$(window).height()}, windowHeight = #{$(document).height()}")

  # show spinner on remote commands
  # $(document).on("ajax:beforeSend", "form[data-remote]", (event) ->
  #    /* spinner show logic */
