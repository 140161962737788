import consumer from './consumer'

$ ->
  if document.location.pathname.startsWith('/run_student_assignment') and team_id = $('#tutor_container').data('team_id')
    team_channel = consumer.subscriptions.create {channel: 'TeamChannel', team_id: team_id, student_assignment_id: document.location.pathname.split('/')[2]},
      connected: false; tutor: null; tutorReady: false
      serverMessages: []; tutorMessages: []

      connected: ->
        ActionCable.logger.log 'Connected'
        while message = @serverMessages.shift()
          ActionCable.logger.log 'Sending buffered message to server', message
          @send message
        @connected = true

      disconnected: ->
        ActionCable.logger.log 'Disconnected'
        @connected = false

      received: (data) ->
        ActionCable.logger.log 'Received message from server', data
        if @tutorReady
          ActionCable.logger.log 'Sending unbuffered message to tutor', data
          @tutor.postMessage data, window.location.origin
        else
          @tutorMessages.push data

      receivedFromTutor: (message) ->
        @tutor = $('#interface').prop('contentWindow')
        ActionCable.logger.log 'Received message from tutor', message
        if message['command'] is 'tutorready'
          while bufferedMessage = @tutorMessages.shift()
            ActionCable.logger.log 'Sending buffered message to tutor', bufferedMessage
            @tutor.postMessage bufferedMessage, window.location.origin
          @tutorReady = true
        if @connected
          ActionCable.logger.log 'Sending unbuffered message to server', message
          @send message
        else
          @serverMessages.push message

    $(window).on 'message', (event) ->
      if event.originalEvent.origin is window.location.origin and event.originalEvent.data['id'] is team_id
        team_channel.receivedFromTutor event.originalEvent.data
