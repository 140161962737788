import TablesController from './tables_controller'

export default class extends TablesController
  @targets: ['package', 'problemSet', 'school', 'course', 'projects', 'form', 'search', 'table', 'report', 'button']

  # Initial report control updating
  connect: -> @updateControls()

  # Initial filtering
  tableTargetConnected: -> @filterTable()

  textInParens: /\((?<text>.+)\)/

  # Toggle problem list problem set
  toggleProblemSet: -> $(@problemSetTarget).val ''

  # Toggle problem list package
  togglePackage: ->
    if $(@problemSetTarget).val()
      package_text = $(@problemSetTarget).find('option:selected').text().match(@textInParens).groups.text
      $(@packageTarget).val $(@packageTarget).find("option:contains('#{package_text}')").val()

  # Toggle student list class
  toggleCourse: -> $(@courseTarget).val '' if @hasCourseTarget

  # Toggle student list school
  toggleSchool: ->
    if $(@courseTarget).val()
      school_text = $(@courseTarget).find('option:selected').text().match(@textInParens).groups.text
      $(@schoolTarget).val $(@schoolTarget).find("option:contains('#{school_text}')").val()

  # Toggle user list projects
  toggleProjects: (event) ->
    developer = /Administrator|Experimenter|Developer/.test $(event.target).find('option:selected').text()
    $(@projectsTarget).val [] unless developer
    $(@projectsTarget).prop 'disabled', not developer

  # Filter user projects so 'All Projects' disables all others and others disable 'All Projects'
  filterProjects: (event) ->
    if $(event.explicitOriginalTarget).val() is '*'
      $(@projectsTarget).val ['*']
    else if $(@projectsTarget).val().includes('*')
      $(@projectsTarget).val $(event.target).val().slice(1)

  # Select a folder and send path to search form
  selectFolder: (event) ->
    deselect = event.ctrlKey || event.metaKey
    $(@formTarget).find('[id$="path"]').val if deselect then '' else $(event.target).data('path')
    $(event.target).closest('.table').find('.tr').removeClass('selected')
    $(event.target).closest('.tr').addClass('selected') unless deselect

  # Search tables
  searchTable: (event) ->
    unless @hasSearchTarget and @searchTargets.includes event.target
      if @hasButtonTarget
        $(@buttonTarget).click()
      else
        Rails.fire(@formTarget, 'submit')
        # @formTarget.dispatchEvent(new Event 'submit', {bubbles: true, cancelable: true})

  # Reset tables
  resetTable: (event) ->
    $filters = $(@formTarget).find('.filters')
    $filters.find('select').val -> $(@).find('option').val()
    $filters.find('input').val -> $(@).data('date') or null
    unless $filters.find('select').length then @filterTable() else @searchTable(event)
    event.preventDefault()

  filterTable: () ->
    if @hasSearchTarget
      terms = $(@searchTarget).val().toLowerCase().split().compact()
      $(@tableTarget).find('.tr').each ->
        text = $(@).find('.td.filter').text().toLowerCase()
        $(@).toggleClass 'filtered', terms.some((term) -> not text.includes(term))
      @reshadeRows @tableTarget

  # Update report controls
  updateControls: ->
    if @hasReportTarget and type = $(@reportTarget).val()
      text = if /student/.test(type) then 'Student' else 'Problem Set'
      sort = $(@formTarget).data('sort')[if text is 'Student' then 0 else 1]

      $(@formTarget).find('[id$="std_id"]').toggleClass 'hide', /student/.test(type)
      $(@formTarget).find('[id$="aps_id"]').toggleClass 'hide', type is 'progress' or /problem_set/.test(type)

      $(@formTarget).find('[id$="pr_sort_type"]').toggleClass 'hide', type is 'progress' or /skills/.test(type)
      $(@formTarget).find('[id$="pr_sort_type"] option').first().text("Sort by #{text}").val(sort) if /performance/.test(type)

      $(@formTarget).find('[id$="sk_group_type"]').toggleClass 'hide', type is 'progress' or /performance/.test(type)
      $(@formTarget).find('[id$="sk_sort_type"]').toggleClass 'hide', type is 'progress' or /performance/.test(type)
      $(@formTarget).find('[id$="sk_group_type"] option').first().text("Group by #{text}").val(sort) if /skills/.test(type)
