import ApplicationController from './application_controller'

export default class extends ApplicationController
  @values = {width: Number, height: Number, borderWidth: Number, borderHeight: Number, reload: Boolean, \
             type: String, path: String, runParams: String, confirmExit: Boolean, exitMessage: String}
  @targets = ['controls']

  # Create functions for review controls
  initialize: ->
    # window.javaScriptInfo = () ->
    window.runTutor = (path, label) ->
      $('.run').attr('data-tutors-path-param': path, 'data-tutors-label-param': label || ''); $('.run').click()
    if @hasControlsTarget
      $controls = $(@controlsTarget)
      window.showTutorParameter = (key) -> $('iframe#interface').data('params')[key]
      window.setTutorParameter = (key, value) -> $('iframe#interface').data('params')[key] = value
      $(window).on 'message', (event) ->
        $controls.prop('contentWindow').postMessage event.originalEvent.data, window.location.origin

  # Run Tutors
  runTutor: ({params}) ->
    # logTime 'Run Button Pressed'
    if not ($dialog = $('dialog')).length
      $('body').append $dialog = $('<dialog>', class: 'dialog', style: '--inline-size: 100%; --block-size: 100%').
        append($('<h2>', text: params.label)).
        append($('<iframe>', name: 'tutor', class: 'tutor', allowfullscreen: true, title: 'Tutor Outer Frame', src: params.path))
        $dialog.on('close', -> location.reload(true)) if @hasReloadValue
    if @hasReloadValue then $dialog.get(0).showModal() else $dialog.get(0).show()

  # Create tutor interface
  createTutor: ->
    if @typeValue in ['html', 'htm']
      $(@element).find('#tutor_container').html($('<iframe>',
        name: 'interface', id: 'interface', allowfullscreen: true, title: 'Tutor Interface', \
        src: @pathValue, 'data-params': @runParamsValue))
      $dialog = $(window.frameElement).closest('dialog')
      $dialog.css '--inline-size', if @hasWidthValue then @widthValue + 2 * @borderWidthValue + 'px' else '100%'
      $dialog.css '--block-size', if @hasHeightValue then @heightValue + 2 * @borderHeightValue + 20 + 'px' else '100%'
      $dialog.css '--margin-inline', if @hasWidthValue then 'auto' else 'none'
      $dialog.css '--margin-block', if @hasHeightValue then 'auto' else 'none'

  # Confirm closing the tutor
  confirmCloseTutor: ->
    if not @hasReloadValue or not @confirmExitValue or confirm(@exitMessageValue)
      $dialog = $(window.frameElement).closest('dialog')
      $dialog.get(0).close()
      $dialog.remove() if @hasReloadValue
