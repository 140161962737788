import TablesController from './tables_controller'

export default class extends TablesController
  @values: {path: String}
  @targets: ['sortable', 'containment']

  # Sort assignment, problem set, & problem lists
  # Sort & group for problems & students
  sortableTargetConnected: (target) ->
    $(target).sortable(
      items: '.sortable', axis: 'y', cancel: 'a, button', tolerance: 'pointer', placeholder: 'tr',
      containment: @containmentTarget, connectWith: '.rowgroup.ui-sortable',
      start: ((event, ui) -> $(ui.placeholder).css('height', $(ui.item).css('height'))),
      out: ((event, ui) ->
        $(@).append(ui.placeholder.clone().addClass('dummy')) unless $(@).children().not('.ui-sortable-helper, .ui-sortable-placeholder').length),
      over: ((event, ui) -> $(@).find('.dummy').remove()),
      stop: ((event, ui) =>
        $.ajax url: @pathValue, type: 'PATCH', dataType: 'script', data: @serialize(target)
        @reshadeRows @containmentTarget))

  serialize: (target) ->
    if $(target).hasClass('rowgroup')
      table_name = @element.id.replace '_table', '_group_ids'
      $(@sortableTargets).map(-> $(@).find('.tr').not('.dummy').map((index, item) =>
        "#{table_name}[#{@id}][]=#{item.id}").get().join('&')).get().join('&')
    else
      table_name = @element.id.replace '_table', '_ids'
      $(target).sortable('serialize', key: "#{table_name}[]", expression: '(.*)')
