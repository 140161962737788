import ApplicationController from './application_controller'

export default class extends ApplicationController

  # Table row reshading
  reshadeRows: (table) ->
    $rows = $(table).find('.tr:not(.hide):not(.filtered)')
    $rows.filter(':even').removeClass('row_even').addClass('row_odd')
    $rows.filter(':odd').removeClass('row_odd').addClass('row_even')

  # Color resetting in add list commands
  resetColor: (event)  -> $(event.target).removeClass(['errors', 'oks'])
